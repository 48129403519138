import React from 'react'
import { Route } from 'react-router-dom'

function RouteWithLayout({
  component: Component,
  layout: Layout,
  pageTitle,
  mobileDrawerConfig,
  desktopDrawerConfig,
  menu,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout
          pageTitle={pageTitle}
          mobileDrawerConfig={mobileDrawerConfig}
          desktopDrawerConfig={desktopDrawerConfig}
          menu={menu}
          {...props}
        >
          <Component pageTitle={pageTitle} {...props} />
        </Layout>
      )}
    />
  )
}

RouteWithLayout.propTypes = {}

export default RouteWithLayout
