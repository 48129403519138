import React from 'react'
import Grid from '@material-ui/core/Grid'

import Spinner from 'components/Spinner'

function ScreenLoader() {
  return (
    <Grid container justify="center">
      <Grid item>
        <Spinner size={30} />
      </Grid>
    </Grid>
  )
}

export default ScreenLoader
