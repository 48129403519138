import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TextField as MuiTextField } from '@material-ui/core'
import classNames from 'classnames'

import styles from './textFieldStyles'

const useStyles = makeStyles(styles)

const TextField = (props) => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    variant,
    fullWidth,
    required,
    className,
    helperText,
    ...rest
  } = props

  const classes = useStyles()

  const helpText = () => {
    if (touched && error && helperText) {
      return `${error} - ${helperText}`
    }
    if (helperText) return helperText
    if (touched && error) return error
    return ''
  }

  return (
    <MuiTextField
      className={classNames(classes.root, className)}
      label={label}
      placeholder={placeholder}
      error={touched && !!error}
      helperText={helpText()}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      {...rest}
      {...input}
    />
  )
}

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  helperText: PropTypes.string,
}

TextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  placeholder: '',
  label: '',
  required: false,
  className: '',
  helperText: '',
  meta: {
    touched: false,
    error: '',
  },
}

export default TextField
