import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import * as ROUTE from './constants'

import RouteWithLayout from './RouteWithLayout'

// Layout
import { Minimal, Default } from 'layout'

// Views
import NotFound from 'components/404'
import Login from 'views/Login'
import Home from 'views/Home'
import IpHome from 'views/IpHome'
import IpExplore from 'views/IpExplore'
import IpScale from 'views/IpExplore/Scale'
import IpDocumentation from 'views/IpDocumentation'

const menuIp = {
  logo: {
    URI:
      'https://firebasestorage.googleapis.com/v0/b/net-123abc.appspot.com/o/assets%2Flogo-text-ip-mapping.svg?alt=media&token=d9df670e-f216-45ae-8076-e669c5204507',
    alt: 'Ip Mapping',
    route: ROUTE.IP_HOME,
  },
  items: [
    { title: 'Overview', route: ROUTE.IP_HOME },
    { title: 'Explore', route: ROUTE.IP_EXPLORE },
    { title: 'Documentation', route: ROUTE.IP_DOCOMENTATION },
  ],
}

const routes = () => {
  return (
    <Switch>
      <Route exact path={ROUTE.NOT_FOUND}>
        <NotFound actionTitle="Back to home" />
      </Route>
      <Route exact path={ROUTE.LOGIN}>
        <Login />
      </Route>
      <RouteWithLayout
        exact
        path={ROUTE.ROOT}
        layout={Default}
        component={Home}
      />
      <RouteWithLayout
        exact
        path={ROUTE.IP_HOME}
        layout={Default}
        component={IpHome}
        menu={menuIp}
      />
      <RouteWithLayout
        exact
        path={ROUTE.IP_EXPLORE}
        layout={Default}
        component={IpExplore}
        menu={menuIp}
      />
      <RouteWithLayout
        exact
        path={ROUTE.IP_DOCOMENTATION}
        layout={Default}
        component={IpDocumentation}
        menu={menuIp}
      />
      <RouteWithLayout
        exact
        path={'/ip/scale/:id'}
        layout={Minimal}
        component={IpScale}
      />
      <Redirect to={ROUTE.NOT_FOUND} />
    </Switch>
  )
}

export default routes
