import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#5959C0',
      light: '#9C9CD9',
      variant: '#9C9CD9',
    },
    secondary: {
      main: '#C62A3A',
      light: '#ff828c',
    },
    // tertiary: {
    //   main: '#9c9cd9',
    // },
    // quaternary: {
    //   main: '#ff828c',
    // },
    background: {
      default: '#15151C',
      paper: '#1E1E24',
      primary: '#15151C',
      secondary: '#1E1E24',
      tertiary: '#27272D',
    },
    // swap primary / tertiary
    // background: {
    //   default: '#27272D',
    //   paper: '#1E1E24',
    //   primary: '#27272D',
    //   secondary: '#1E1E24',
    //   tertiary: '#15151C',
    // },
    muted: {
      main: '#545458',
    },
    error: {
      main: '#b00020',
    },
    fill: {
      main: 'rgba(255, 255, 255, 0.15)',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.83)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      tertiary: 'rgba(255, 255, 255, 0.3)',
      highlight: 'rgba(255, 255, 255, 1)',
    },
  },
  shape: {
    borderRadius: 12,
  },
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.12)',
      },
    },
  },
})

export default theme
