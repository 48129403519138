import React from 'react'
import { makeStyles } from '@material-ui/styles'
import TopBar from 'components/TopBar'

const useStyles = makeStyles((theme) => ({
  topBar: theme.mixins.toolbar,
  menuBar: {
    height: '48px',
    width: '100%',
  },
}))

const Default = (props) => {
  const { children, menu } = props
  const classes = useStyles()
  return (
    <React.Fragment>
      <TopBar menu={menu} />
      <div className={classes.topBar} />
      {!!menu && <div className={classes.menuBar} />}
      {children}
    </React.Fragment>
  )
}

export default Default
