import React from 'react'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import {
  Box,
  Paper,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Typography,
} from '@material-ui/core'

const SearchBlock = (props) => {
  const {
    searchQuery,
    title,
    cellOneTitle = 'name',
    cellTwoTitle = 'id',
    searchLabel = 'Search by name',
    rewrite,
    fuzzyQuery,
    onClick,
  } = props
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState(
    (rewrite && rewrite.name) || 'name'
  )
  const [page] = React.useState(0)
  const [rowsPerPage] = React.useState(10)
  const [searchString, setSearchString] = React.useState('')
  const [search, setSearch] = React.useState(undefined)
  const [filterState, setFilterState] = React.useState({ nameFilter: '' })
  const client = useApolloClient()

  const getFilter = () => {
    const filtername = ((rewrite && rewrite.name) || 'name') + '_contains'
    return filterState.nameFilter.length > 0
      ? { [filtername]: filterState.nameFilter }
      : {}
  }

  const { loading, data, error } = useQuery(searchQuery, {
    variables: {
      first: rowsPerPage,
      offset: rowsPerPage * page,
      orderBy: orderBy + '_' + order,
      filter: getFilter(),
    },
  })

  const handleSortRequest = (property) => {
    const newOrderBy = property
    let newOrder = 'desc'

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc'
    }
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleFilterChange = (filterName) => (event) => {
    const val = event.target.value
    setFilterState((oldFilterState) => ({
      ...oldFilterState,
      [filterName]: val,
    }))
  }

  const handleSearchString = async (event) => {
    const val = event.target.value
    setSearchString(val)
    if (fuzzyQuery && val) {
      const {
        data: { r },
      } = await client.query({
        query: fuzzyQuery,
        variables: {
          searchString: val,
        },
      })
      return setSearch(r)
    }
    return setSearch(undefined)
  }

  return (
    <Paper>
      <Box p={2} my={2}>
        <Typography>{title}</Typography>
        <TextField
          id={`${title}Search`}
          label={searchLabel}
          value={fuzzyQuery ? searchString : filterState.nameFilter}
          onChange={
            fuzzyQuery ? handleSearchString : handleFilterChange('nameFilter')
          }
          margin="normal"
          variant="outlined"
          type="text"
          fullWidth
        />
        {loading && !error && <p>Loading...</p>}
        {error && !loading && <p>Error</p>}
        {search && (
          <Table>
            <TableBody>
              {search.map((n) => {
                return (
                  <TableRow key={n.id} hover onClick={() => onClick(n)}>
                    <TableCell component="th" scope="row">
                      {n[(rewrite && rewrite.name) || 'name']}
                    </TableCell>
                    <TableCell>{n.id}</TableCell>
                    <TableCell>{n.numReviews}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
        {!search && data && !loading && !error && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  key={`${title}Name`}
                  sortDirection={orderBy === 'name' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={order}
                    onClick={() => handleSortRequest('name')}
                  >
                    {cellOneTitle}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  key={`${title}Id`}
                  sortDirection={orderBy === 'id' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={order}
                    onClick={() => handleSortRequest('id')}
                  >
                    {cellTwoTitle}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.search.map((n) => {
                return (
                  <TableRow key={n.id} hover onClick={() => onClick(n)}>
                    <TableCell component="th" scope="row">
                      {n[(rewrite && rewrite.name) || 'name']}
                    </TableCell>
                    <TableCell>{n.id}</TableCell>
                    <TableCell>{n.numReviews}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Paper>
  )
}

SearchBlock.defaultProps = {
  onClick: (d) => {console.log(d)},
}

export default SearchBlock
