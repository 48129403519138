import React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import TextField from '@material-ui/core/TextField'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/SearchSharp'

const Autocomplete = ({ query }) => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])

  const [fuzzySearch, { data, loading, error }] = useLazyQuery(query)

  React.useEffect(() => {
    if (!data) return
    const keys = Object.keys(data)
    console.log(keys)
    const options = [...data.scale, ...data.domain, ...data.item]
    console.log('OPTIONS', options)
    setOptions(options)
  }, [data])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const handleSearch = React.useCallback(async (searchString) => {
    if (!searchString) return
    await fuzzySearch({ variables: { searchString } })
  })

  return (
    <MuiAutocomplete
      id="autocomplete"
      style={{ width: '100%', paddingLeft: 12, paddingRight: 12 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => {
        return option.name + ' - [' + option.id + ']'
      }}
      groupBy={(option) => option.__typename}
      options={options}
      loading={loading}
      onChange={(_, d) => console.log('ON_CHANGE', d)}
      onInputChange={(_, d) => handleSearch(d)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {/* {params.InputProps.endAdornment} */}
              </React.Fragment>
            ),
            startAdornment: <SearchIcon />,
          }}
        />
      )}
    />
  )
}

export default Autocomplete
