import React from 'react'
import { useHistory } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles'
import TopBar from 'components/TopBar'
import * as ROUTE from 'routes/constants'
import { Typography, Container, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    background: '#d3d3d3',
    position: 'relative',
    // backgroundImage:
    //   'url("https://firebasestorage.googleapis.com/v0/b/hcn-ip.appspot.com/o/assets%2Fpersonality_and_team_assesment.jpg?alt=media&token=210accb1-eb5f-4772-9ad3-3909f05fcb2d")',
    backgroundSize: '100% auto',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    padding: '52px 0 0 0',
    margin: '0 calc(50% - 50vw)',
    maxWidth: 'inherit',
    color: '#000',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      minHeight: '60vh',
      backgroundSize: 'cover',
      backgroundPosition: 'right',
      padding: '90px 64px 70px 80px',
      color: '#fff',
    },
  },
  heroContent: {
    color: 'inherit',
    backgroundColor: '#fff',
    marginTop: '30%',
    padding: '10px 16px 0',
    paddingBottom: '40px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 40px 0',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      marginTop: 0,
      padding: '10px 50px 0',
    },
  },
  heroTitle: {
    fontSize: '30px',
    lineHeight: '1.2em',
    maxWidth: '500px',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      fontSize: '44px',
      lineHeight: '1.2em',
    },
  },
  heroSubtitle: {
    fontSize: '16px',
    maxWidth: '412px',
    marginBottom: theme.spacing(2.5),
  },
  metrics: {
    backgroundColor: '#6f44a1',
    color: '#fff',
  },
  metricsBox: {
    textAlign: 'center',
    padding: '24px 12px',
  },
}))

const IpHome = () => {
  const history = useHistory()
  const classes = useStyles()

  const handleNavigation = (path) => () => {
    history.push(path)
  }

  return (
    <React.Fragment>
      <header className={classes.header}>
        <Container maxWidth="lg" className={classes.heroContent}>
          <Typography
            className={classes.heroTitle}
            variant="h1"
            color="inherit"
          >
            Personality and
            <br />
            Team Mapping Platform
          </Typography>
          <div className={classes.heroSubtitle}>
            Explore where graph based personality insight and team analytics can
            take your business
          </div>
        </Container>
      </header>
      <Container component="section" className={classes.metrics}>
        <Grid container>
          <Grid item xs={12} sm={4} className={classes.metricsBox}>
            <Typography variant="h3">305</Typography>
            <Typography variant="h5">Scales</Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.metricsBox}>
            <Typography variant="h3">612</Typography>
            <Typography variant="h5">Domains</Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.metricsBox}>
            <Typography variant="h3">3538</Typography>
            <Typography variant="h5">Items</Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default IpHome
