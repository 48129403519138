import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme, props) => ({
  avatarRoot: {
    borderRadius: '6px',
  },
  large: {
    height: '56px',
    width: '56px',
  },
}))

const StyledAvatar = (props) => {
  const { large } = props
  const classes = useStyles()

  const avatarClasses = classNames({
    [classes.avatarRoot]: true,
    [classes.large]: !!large || false,
  })

  return (
    <Avatar className={avatarClasses} {...props}>
      {props.children}
    </Avatar>
  )
}

export default StyledAvatar
