import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  List,
  ListItem,
  Box,
  ListItemIcon,
  Typography,
} from '@material-ui/core'

import MailIcon from '@material-ui/icons/Mail'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import DashboardIcon from '@material-ui/icons/Dashboard'
import IpIcon from '@material-ui/icons/LocalLibrary'

import Menu from './Menu'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  sideOuterContainer: {
    flex: '1 0 auto',
    padding: theme.spacing(1),
    margin: 0,
  },
  menuItem: {
    borderRadius: '8px',
  },
}))

const iconHandler = {
  mail: <MailIcon />,
  dashboard: <DashboardIcon />,
  ip: <IpIcon />,
}

function DrawerMenu(props) {
  const { settings, onClick } = props
  const classes = useStyles()
  return (
    <>
      <div className={classes.toolbar} />
      <Grid
        className={classes.sideOuterContainer}
        container
        direction="column"
        justify="space-between"
      >
        <Grid item xs>
          <List onClick={onClick}>
            {settings.menuItems.map((item, index) => (
              <Box key={item.title} my={1}>
                <ListItem
                  button
                  component={Link}
                  to={item.route}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {item.icon && iconHandler[item.icon]}
                  </ListItemIcon>
                  <Typography variant="h6">{item.title}</Typography>
                </ListItem>
              </Box>
            ))}
          </List>
        </Grid>
        <Grid item>
          <Menu />
        </Grid>
      </Grid>
    </>
  )
}

DrawerMenu.propTypes = {}
DrawerMenu.defaultProps = {
  settings: {
    menuItems: [],
  },
}

export default DrawerMenu
