import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Avatar from 'components/Avatar'
import ExpandIcon from '@material-ui/icons/ExpandMore'

import { StyledMenu, StyledMenuItem } from './Menu'
// import DarkModeToggler from 'components/DarkModeToggler'

const useStyles = makeStyles((theme) => ({
  btnBase: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: '8px',
    overflow: 'hidden',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  resetLineheight: {
    lineHeight: 1,
  },
}))

function AvaterMenu(props) {
  const {
    avatarURL,
    displayName,
    handle,
    onLogout,
    onToggleConsole,
    onCreateBusiness,
    isMeHome,
    company,
  } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <List>
        <ListItem
          disableRipple
          button
          onClick={handleClick}
          className={classes.btnBase}
        >
          <Grid container wrap="nowrap" spacing={1}>
            <Grid item xs="auto">
              <Avatar src={avatarURL} />
            </Grid>
            <Grid
              xs
              container
              item
              direction="column"
              zeroMinWidth
              justify="center"
            >
              <Grid item xs zeroMinWidth>
                <Typography
                  color="textPrimary"
                  align="left"
                  variant="body1"
                  noWrap
                >
                  {displayName}
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  className={classes.resetLineheight}
                  color="textSecondary"
                  align="left"
                  variant="body2"
                  noWrap
                >
                  {handle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.alignCenter}>
              <ExpandIcon />
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <StyledMenu
        id="avatar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem>
          <DarkModeToggler />
        </StyledMenuItem> */}
        {company && company.xid ? (
          <StyledMenuItem onClick={onToggleConsole}>
            <Typography>{isMeHome ? 'Reachout Console' : 'Me Home'}</Typography>
          </StyledMenuItem>
        ) : (
          <StyledMenuItem onClick={onCreateBusiness}>
            <Typography>Create business</Typography>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={onLogout}>
          <Typography>Log out</Typography>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}

AvaterMenu.propTypes = {
  avatarURL: PropTypes.string,
  displayName: PropTypes.string,
  handle: PropTypes.string,
  onLogout: PropTypes.func,
  onToggleConsole: PropTypes.func,
  onCreateBusiness: PropTypes.func,
  isMeHome: PropTypes.bool,
  company: PropTypes.object,
}

export default AvaterMenu
