import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '12px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  backIcon: {
    '& path': {
      transform: 'translate(4px, 0px)',
    },
  },
  title: {
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
}))

const Minimal = (props) => {
  const {
    children,
    history: { goBack },
    pageTitle,
  } = props
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <AppBar
        position="relative"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <ToolBar>
          <IconButton onClick={() => goBack()} className={classes.iconButton}>
            <BackIcon className={classes.backIcon} />
          </IconButton>
          <Typography className={classes.title} variant="h6">
            {pageTitle}
          </Typography>
        </ToolBar>
      </AppBar>
      <Container maxWidth="lg" disableGutters>
        {children}
      </Container>
    </>
  )
}

export default Minimal
