import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import {unregister} from './registerServiceWorker'
import App from './App'
import { Hcn } from 'api' // Apollo HOC [HumanCloudNetwork Connector]

const Main = () => (
  <Hcn>
    <Router>
      <App />
    </Router>
  </Hcn>
)

ReactDOM.render(<Main />, document.getElementById('root'))
unregister()
