import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

// TODO: Add theming based on types

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    backgroundColor: theme.palette.error.main,
  },
  message: {
    color: theme.palette.error.contrastText,
  },
}));

const InlineMessage = props => {
  const { message, type } = props;

  const classes = useStyles();

  return (
    <div className={classes.root} type={type}>
      <Typography className={classes.message} variant="body1">
        {message}
      </Typography>
    </div>
  );
};

InlineMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'warning']),
};

InlineMessage.defaultProps = {
  type: 'error',
};

export default InlineMessage;
