import React from 'react'
import { Form, Field } from 'react-final-form'
import classNames from 'classnames'
import { Link as NavLink } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import TextField from 'components/TextField'
import InlineMessage from 'components/InlineMessage'

import * as ROUTE from 'routes/constants'

import {
  formatToLowerCase,
  parseToLowerCase,
  validateEmail,
  validatePassword,
} from 'utils/formUtilities'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  disabled: {
    pointerEvents: 'none',
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    marginTop: '8px',
    marginBottom: '-4px',
  },
}))

export default function SignIn(props) {
  const { onSubmit, err, processing, initialValues } = props
  const classes = useStyles()

  return (
    <Container component="main" maxWidth={false} disableGutters>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {err && err && <InlineMessage message={err} />}
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting, values }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Field
                name="email"
                component={TextField}
                type="email"
                label="Email Address"
                validate={validateEmail}
                format={formatToLowerCase}
                parse={parseToLowerCase}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
              />
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                validate={validatePassword}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />
              <Field name="remember" label="Remember me" type="checkbox">
                {({ input, label }) => (
                  <FormControlLabel
                    className={classes.checkbox}
                    onChange={input.onChange}
                    checked={input.checked}
                    control={<Checkbox color="primary" />}
                    label={<Typography variant="caption">{label}</Typography>}
                  />
                )}
              </Field>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={submitting || processing}
              >
                Sign In
              </Button>
              <Box py={1}>
                <Grid container>
                  <Grid item xs>
                    <Link
                      component={NavLink}
                      to={ROUTE.RESET_PWD}
                      variant="body2"
                      className={classNames({
                        [classes.disabled]: submitting || processing,
                      })}
                      color="textPrimary"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component={NavLink}
                      to={{
                        pathname: ROUTE.SIGNUP,
                        search: values.email
                          ? `?email=${values.email}`
                          : undefined,
                      }}
                      variant="body2"
                      className={classNames({
                        [classes.disabled]: submitting || processing,
                      })}
                      color="textPrimary"
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Form>
      </div>
    </Container>
  )
}
