import React from 'react'
// import { useHistory } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Helmet } from 'react-helmet'
import { fb } from 'api'

import hcnLightTheme from 'theme/hcnLightTheme'
import hcnDarkTheme from 'theme/hcnDarkTheme'

import Routes from 'routes'
import ScreenLoader from 'components/ScreenLoader'

// import * as ROUTE from 'routes/constants'

const App = () => {
  // let history = useHistory()
  const [intializing, setInitializing] = React.useState(true)
  const mode = 'dark'
  const hcnTheme = mode === 'light' ? hcnDarkTheme : hcnLightTheme

  React.useEffect(() => {
    async function getAuthUser() {
      try {
        const authUser = await fb.doGetCurrentUser()
        if (authUser) {
          // return history.push(ROUTE.DASHBOARD)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setInitializing(false)
      }
    }
    getAuthUser()
  })

  return (
    <ThemeProvider theme={hcnTheme}>
      <Helmet>
        <title>IP Admin</title>
        <link rel="icon" href="favicon.svg"></link>
        <link rel="mask-icon" href="mask-icon.svg" color="#0565D6"></link>
        <link rel="apple-touch-icon" href="apple-touch-icon.png"></link>
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <CssBaseline />
      {intializing ? <ScreenLoader /> : <Routes />}
    </ThemeProvider>
  )
}

export default App
