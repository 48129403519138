import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import qs from 'query-string'

import { fb, hcnClient } from 'api'

import { EmailPwdLoginForm } from 'components/AuthLogin'

import * as ROUTE from 'routes/constants'

const GET_CURRENT_USER = gql`
  query getCurrentUserQuery($uid: ID!) {
    User(uid: $uid) {
      uid
      displayName
      email
      businesses {
        Business {
          xid
          name
        }
      }
    }
  }
`

const Login = () => {
  const qo = qs.parse(useLocation().search)
  const history = useHistory()
  const [processing, setProcessing] = React.useState(false)
  const [err, setErr] = React.useState('')

  const [getCurrentUser, { loading }] = useLazyQuery(GET_CURRENT_USER, {
    onCompleted: () => {
      history.replace(ROUTE.DASHBOARD)
    },
    onError: () => setErr('Oppsss.. something went wrong'),
  })

  const loginUser = async (formData) => {
    try {
      setProcessing(true)
      setErr('')
      const { user } = await fb.doSignInWithEmailAndPassword(
        formData.email,
        formData.password
      )
      hcnClient.clearStore()
      await getCurrentUser({
        variables: {
          uid: user.uid,
        },
      })
    } catch (e) {
      if (e.code) {
        setErr(e.message)
      }
      console.log(e)
    } finally {
      setProcessing(false)
    }
  }
  return (
    <Container maxWidth="sm">
      <Paper>
        <Box p={2} mt={3}>
          <EmailPwdLoginForm
            onSubmit={loginUser}
            processing={processing || loading}
            err={err}
            initialValues={
              qo && qo.email
                ? { email: qo.email, remember: true }
                : { remember: true }
            }
          />
        </Box>
      </Paper>
    </Container>
  )
}

export default Login
