import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField, Container, Paper, Chip, Tooltip } from '@material-ui/core'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import SearchIcon from '@material-ui/icons/SearchSharp'
import copy from 'copy-to-clipboard'

const useStyles = makeStyles((theme) => ({
  results: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipContainer: {
    padding: theme.spacing(0.5),
  },
}))

const AutoCompleteChip = ({ fuzzyQuery, query, onClick }) => {
  const classes = useStyles()
  const searchMountRef = React.useRef(null)
  const [searchString, setSearchString] = React.useState('')
  const [search, setSearch] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)
  const client = useApolloClient()

  const { data, loading: l, refetch } = useQuery(query, {
    variables: {
      firstScale: 10,
      firstDomain: 20,
      firstItem: 8,
    },
  })

  React.useEffect(() => {
    if (!data) return
    setSearch(data)
  }, [data])

  const handleSearchString = async (event) => {
    const val = event.target.value
    setSearchString(val)
    try {
      if (!!fuzzyQuery && val) {
        setLoading(true)
        const { data: d } = await client.query({
          query: fuzzyQuery,
          variables: {
            searchString: val,
          },
        })
        return setSearch(d)
      }
      setSearch(data)
      return
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = (item) => () => {
    copy(item.id)
    onClick(item)
    console.log(item)
  }

  const getColor = {
    Scale: 'primary',
    Domain: 'secondary',
  }

  const getVariant = {
    Scale: 'default',
    Domain: 'default',
  }

  const getChips = () => {
    if (!search) return null
    let chips = []
    Object.keys(search).forEach((key, index) => {
      search[key].map((r) =>
        chips.push(
          <div key={r.id} className={classes.chipContainer}>
            <Tooltip title={`${r.__typename}: ${r.id}`} placement="top">
              <Chip
                label={r.name}
                onClick={handleClick(r)}
                variant={getVariant[r.__typename] || 'outlined'}
                color={getColor[r.__typename] || 'default'}
              />
            </Tooltip>
          </div>
        )
      )
    })
    return chips
  }

  return (
    <Container>
      <TextField
        id="search"
        value={searchString}
        onChange={handleSearchString}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
      <div className={classes.results}>{getChips()}</div>
    </Container>
  )
}

export default AutoCompleteChip
