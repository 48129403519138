import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#373791',
      light: '#262665',
      variant: '#262665',
    },
    secondary: {
      main: '#c62a3a',
      light: '#ff828c',
    },
    background: {
      default: '#fff',
      paper: '#fff',
      primary: '#ededf2',
      secondary: '#fff',
      tertiary: '#dedee6',
    },
    muted: {
      main: '#15151c',
    },
    error: {
      main: '#b00020',
    },
    fill: {
      main: 'rgba(0, 0, 0, 0.15)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.83)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      tertiary: 'rgba(0, 0, 0, 0.3)',
      highlight: 'rgba(0, 0, 0, 1)',
    },
  },
  shape: {
    borderRadius: 12,
  },
})

export default theme
