import React, { Component } from 'react'

import AvatarMenu from 'components/AvatarMenu'

class Menu extends Component {
  render() {
    const { user = {}, logout} = this.props
    const { photoURL, email, displayName } = user
    return (
      <AvatarMenu
        avatarURL={photoURL || ''}
        handle={email || ''}
        displayName={displayName || ''}
        onLogout={logout}
      />
    )
  }
}

export default Menu
