import gql from 'graphql-tag'

export const GET_SCALE_BY_ID = gql`
  query getScaleById($id: String) {
    scaleByDomain: Scale(id: $id) {
      id
      name
      domains {
        id
        name
        items(filter: { scales_some: { Scale: { id: $id } } }) {
          id
          text
          keyed: scales(filter: { Scale: { id: $id } }) {
            key
          }
        }
      }
    }
    scaleByItem: Scale(id: $id) {
      id
      name
      items {
        key
        alpha
        item: Item {
          id
          text
          domains(filter: { scales_some: { id: $id } }) {
            id
            name
          }
        }
      }
      domains {
        id
        name
      }
    }
  }
`
