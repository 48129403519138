import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Buttton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const NotFound = (props) => {
  const { actionPath, actionTitle } = props
  console.log('PROPS', props)
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography paragraph>Page not found</Typography>
      <Buttton
        variant="contained"
        color="primary"
        component={Link}
        to={actionPath}
      >
        {actionTitle}
      </Buttton>
    </Box>
  )
}

export default NotFound
