import ApolloClient from 'apollo-boost'
import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/react-hooks'
import { fb } from 'api'

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:3002/',
  request: async (operation) => {
    let token
    const user = await fb.doGetCurrentUser()
    if (user) {
      token = await fb.doGetTokenCurrentUser()
    }
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

const Apollo = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

Apollo.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Apollo
