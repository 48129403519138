export const NOT_FOUND = '/404'
export const ROOT = '/'
export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const DASHBOARD = '/dashboard'
export const RESET_PWD = '/forgot_password'

// IP
export const IP_HOME = '/ip'
export const IP_EXPLORE = '/ip/explore'
export const IP_DOCOMENTATION = '/ip/documentation'
