import validate from 'validate.js'

export const formatToLowerCase = (v) => (v ? v.toLowerCase() : '')
export const parseToLowerCase = (v) => v && v.toLowerCase()
export const formatToUpperCase = (v) => (v ? v.toUpperCase() : '')
export const parseToUpperCase = (v) => v && v.toUpperCase()
export const isNumber = (v) => validate.isNumber(v)

// Field Validators - api: https://validatejs.org/
const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 6,
      maximum: 128,
    },
  },
  fullName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  displayName: {
    length: {
      minimum: 3,
      maximum: 20,
    },
  },
  initials: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1,
      maximum: 4,
    },
  },
  checkbox: {
    presence: {
      message: 'is required',
    },
    inclusion: {
      within: [true],
      message: 'is required',
    },
  },
  location: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  type: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  description: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  title: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  amount: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 1000,
    },
  },
}

const validateField = (value, type) => {
  const error = validate({ [type]: value }, schema)
  return (error && error[type] && error[type][0]) || undefined
}

export const validateDisplayName = (value) =>
  validateField(value, 'displayName')
export const validateEmail = (value) => validateField(value, 'email')
export const validatePassword = (value) => validateField(value, 'password')
export const validateFullName = (value) => validateField(value, 'fullName')
export const validateFirstName = (value) => validateField(value, 'firstName')
export const validateLastName = (value) => validateField(value, 'lastName')
export const validateInitials = (value) => validateField(value, 'initials')
export const validateCheckbox = (value) => validateField(value, 'checkbox')
export const validateLocation = (value) => validateField(value, 'location')
export const validateType = (value) => validateField(value, 'type')
export const validateTitle = (value) => validateField(value, 'title')
export const validateDescription = (value) =>
  validateField(value, 'description')
export const validateTokenAmount = (value) => validateField(value, 'amount')
