import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  ButtonBase,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core'
import * as ROUTE from 'routes/constants'

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#fff',
  },
  topNav: {},
  brand: {
    height: '32px',
    width: 'auto',
  },
  tabIndicator: {
    backgroundColor: '#6f44a1',
  },
  tabLabel: {
    textTransform: 'capitalize',
  },
  menuBar: {
    display: 'flex',
    alignItems: 'center',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.divider,
    paddingLeft: theme.spacing(3),
  },
  menuBarLogo: {
    height: '18px',
    width: 'auto',
    marginRight: theme.spacing(2),
  },
}))

const TopBar = ({ menu }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleNavigate = (route) => () => {
    history.push(route)
  }

  return (
    <>
      <AppBar
        className={classes.appBar}
        color="transparent"
        position="fixed"
        elevation={3}
      >
        <Toolbar className={classes.topNav}>
          <ButtonBase
            edge="start"
            aria-label="go-to-home"
            disableRipple
            onClick={handleNavigate(ROUTE.ROOT)}
          >
            <img
              className={classes.brand}
              src="https://firebasestorage.googleapis.com/v0/b/net-123abc.appspot.com/o/assets%2Flogo-text-net-purple.svg?alt=media&token=b10843d3-8cda-41fe-983e-e14692ac0cf2"
            />
          </ButtonBase>
        </Toolbar>
        {!!menu && <MenuBar menu={menu} />}
      </AppBar>
    </>
  )
}

export default TopBar

export const MenuBar = ({ menu }) => {
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const [tab, setTab] = React.useState(ROUTE.IP_HOME)

  React.useEffect(() => {
    setTab(pathname)
  }, [pathname])

  const handleChangeTab = (_, route) => {
    history.push(route)
  }
  const handleNavigate = (route) => () => {
    history.push(route)
  }

  return (
    <div className={classes.menuBar}>
      {!!menu.logo && (
        <ButtonBase
          edge="start"
          aria-label="menu.logo.alt"
          disableRipple
          onClick={handleNavigate(menu.logo.route)}
          disabled={!menu.logo.route}
        >
          <img
            className={classes.menuBarLogo}
            src={menu.logo.URI}
            alt={menu.logo.alt}
          />
        </ButtonBase>
      )}
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        classes={{ indicator: classes.tabIndicator }}
      >
        {menu.items.map((m) => (
          <Tab
            key={m.route}
            value={m.route}
            label={
              <Typography className={classes.tabLabel}>{m.title}</Typography>
            }
          />
        ))}
      </Tabs>
    </div>
  )
}
