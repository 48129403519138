import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_SCALE_BY_ID } from '../../containers/Ip/query'

const Scale = () => {
  const { id } = useParams()
  const { data, loading } = useQuery(GET_SCALE_BY_ID, { variables: { id } })
  console.log(id)
  console.log(data)

  return (
    <div>
      {loading && <span>loading...</span>}
      {data && JSON.stringify(data.scaleByDomain[0])}
    </div>
  )
}

export default Scale
