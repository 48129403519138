import React from 'react'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'

import SearchBlock from 'components/SearchBlock'
import Autocomplete from 'components/Autocomplete'
import AutocompleteChip from 'components/AutocompleteChip'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
    flex: 1,
  },
}))

const FUZZY_SCALE_SEARCH = gql`
  query fuzzyScaleNameSearch($searchString: String!) {
    r: fuzzyScaleByName(searchString: $searchString) {
      id
      name
    }
  }
`

const FUZZY_DOMAIN_SEARCH = gql`
  query fuzzyDomainNameSearch($searchString: String!) {
    r: fuzzyDomainByName(searchString: $searchString) {
      id
      name
    }
  }
`

const FUZZY_ITEM_SEARCH = gql`
  query fuzzyItemTextSearch($searchString: String!) {
    r: fuzzyItemByText(searchString: $searchString) {
      id
      text
    }
  }
`

const FUZZY_IP_SEARCH = gql`
  query fuzzySeach($searchString: String) {
    scale: fuzzyScaleByName(searchString: $searchString, first: 10) {
      id
      name
    }
    domain: fuzzyDomainByName(searchString: $searchString, first: 10) {
      id
      name
    }
    item: fuzzyItemByText(searchString: $searchString, first: 20) {
      id
      name: text
    }
  }
`

const GET_DOMAIN = gql`
  query scalesPaginateQuery(
    $first: Int
    $offset: Int
    $orderBy: [_DomainOrdering]
    $filter: _DomainFilter
  ) {
    search: Domain(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      name
    }
  }
`

const GET_ITEM = gql`
  query scalesPaginateQuery(
    $first: Int
    $offset: Int
    $orderBy: [_ItemOrdering]
    $filter: _ItemFilter
  ) {
    search: Item(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      text
    }
  }
`

const GET_SCALE = gql`
  query scalesPaginateQuery(
    $first: Int
    $offset: Int
    $orderBy: [_ScaleOrdering]
    $filter: _ScaleFilter
  ) {
    search: Scale(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      name
    }
  }
`

const IP_QUERY = gql`
  query ipQuery($firstScale: Int, $firstDomain: Int, $firstItem: Int) {
    scale: Scale(first: $firstScale) {
      id
      name
    }
    domain: Domain(first: $firstDomain) {
      id
      name
    }
    item: Item(first: $firstItem) {
      id
      name: text
    }
  }
`

const IpExplore = () => {
  const history = useHistory()
  const classes = useStyles()

  const handleOnClick = (data) => {
    const { id, __typename } = data
    if (__typename === "Scale") {
      history.push(`/ip/${__typename.toLowerCase()}/${id}`)
    }
  }

  return (
    <Container className={classes.root}>
      <div className={classes.searchContainer}>
        <AutocompleteChip
          fuzzyQuery={FUZZY_IP_SEARCH}
          query={IP_QUERY}
          onClick={handleOnClick}
        />
      </div>
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <SearchBlock
            onClick={handleOnClick}
            searchQuery={GET_SCALE}
            fuzzyQuery={FUZZY_SCALE_SEARCH}
            title="Scales"
          />
        </Grid>
        <Grid item xs={12}>
          <SearchBlock
            searchQuery={GET_DOMAIN}
            title="Domains"
            fuzzyQuery={FUZZY_DOMAIN_SEARCH}
          />
        </Grid>
        <Grid item xs={12}>
          <SearchBlock
            searchQuery={GET_ITEM}
            fuzzyQuery={FUZZY_ITEM_SEARCH}
            title="Items"
            rewrite={{ name: 'text' }}
          />
        </Grid>
      </Grid> */}
    </Container>
  )
}

export default IpExplore
