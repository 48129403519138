import React from 'react'

const Home = () => {
  return (
    <div>
      home
    </div>
  )
}

export default Home
